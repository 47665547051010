<template>
  <div>
    <RecruitPayment @ccSubmit="onCCSubmit" />
    <LoadingDialog v-if="loading" />
  </div>
</template>

<script>
import { createUserStripeSubscription } from '@/helpers/firebase-functions'
import LoadingDialog from '@/components/LoadingDialog'
import RecruitPayment from '@/components/payment/recruit'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'
import * as Sentry from '@sentry/browser'
import { createNamespacedHelpers } from 'vuex'

const { mapState, mapActions } = createNamespacedHelpers('auth')
export default {
  components: {
    RecruitPayment,
    LoadingDialog
  },
  props: {
    redirect: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      idempotencyKey: '',
      loading: false
    }
  },
  computed: {
    ...mapState([
      'uid'
    ])
  },
  methods: {
    ...mapActions(['refreshUserDocument']),
    async onCCSubmit ({ token, couponCode, priceIds }) {
      this.loading = true
      try {
        await createUserStripeSubscription({ token: token.id, couponCode, priceIds, idempotencyKey: this.idempotencyKey })
        await this.refreshUserDocument()
        analyticsEvent(EVENT_NAMES.COMPLETED_PAYMENT)
        if (this.redirect) {
          this.$router.replace(decodeURIComponent(this.redirect))
        } else {
          this.$router.replace({ name: 'recruit' })
        }
      } catch (error) {
        Sentry.captureException(error)
        console.error('submit stripe token error', error) // eslint-disable-line no-console
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_RECRUIT_PAYMENT)
    this.idempotencyKey = `${this.uid}${new Date().getTime()}`
  }
}
</script>

<style>
</style>
