<template>
  <v-card>
    <v-text-field
      v-model="couponCode"
      dark
      background-color="primary"
      placeholder="クーポンコード"
      filled
      :error="error"
      :error-messages="errorMessages"
      :disabled="loading"
    ></v-text-field>

    <v-card-actions>
      <v-spacer />
      <v-btn
        :disabled="!hasCouponCode"
        :dark="hasCouponCode"
        color="primary"
        @click="onClickAdd"
        :loading="loading"
      >追加する</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import * as Sentry from '@sentry/browser'
const { mapActions } = createNamespacedHelpers('stripe')

export default {
  data () {
    return {
      couponCode: null,
      loading: false,
      error: false
    }
  },
  computed: {
    hasCouponCode () {
      return this.couponCode !== null && this.couponCode.length > 0
    },
    errorMessages () {
      return this.error ? ['クーポンコードが正しくありません。'] : []
    }
  },
  methods: {
    ...mapActions(['getCouponName']),
    async onClickAdd () {
      this.error = false
      if (this.hasCouponCode) {
        try {
          this.loading = true
          const { data: couponName } = await this.getCouponName({ couponId: this.couponCode })
          this.$emit('addedCoupon', { couponCode: this.couponCode, couponName })
        } catch (err) {
          Sentry.captureException(err)
          /* eslint-disable-next-line */
          console.error(err)
          this.error = true
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style>
</style>
