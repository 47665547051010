<template>
  <v-dialog v-model="dialog" width="500" max-width="90vw">
    <template v-slot:activator="{ on }">
      <v-btn outlined color="primary" v-on="on">クーポン入力</v-btn>
    </template>

    <CouponForm @addedCoupon="addedCoupon" />
  </v-dialog>
</template>

<script>
import CouponForm from './coupon-form'

export default {
  components: {
    CouponForm
  },
  data () {
    return {
      dialog: null
    }
  },
  methods: {
    addedCoupon (coupon) {
      this.$emit('addedCoupon', coupon)
      this.dialog = false
    }
  }
}
</script>

<style>
</style>
