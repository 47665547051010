<template>
  <transition name="slide-fade" mode="out-in">
    <v-container grid-list-md fill-height v-if="clicked === false">
      <v-layout column justify-center>
        <v-flex shrink>
          <v-card>
            <v-card-title>ありがとうございます！</v-card-title>
            <v-card-text>
              <v-alert type="info">{{ serviceName }}にサインアップするにはクレジットカード情報の入力をお願いいたします。</v-alert>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="primary" @click="onCancel">戻る</v-btn>
              <v-btn color="primary" @click="clicked = true">次へ</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <CCForm @ccSubmit="$emit('ccSubmit', $event)" v-else />
  </transition>
</template>

<script>
import { serviceName } from '@/truffle.config'
import CCForm from './cc-form'
export default {
  components: {
    CCForm
  },
  data () {
    return {
      serviceName,
      clicked: false
    }
  },
  methods: {
    onCancel () {
      this.$router.replace({ name: 'onboard-profile' })
    }
  }
}
</script>

<style>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-enter {
  transform: translateY(-10px);
  opacity: 0;
}

.slide-fade-enter-to {
  transform: translateY(0);
  opacity: 1;
}

.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>
