<template>
  <v-row dense>
    <v-col cols="12" sm="4" md="4" lg="4">
      <v-card class="flexcard" height="100%" outlined>
        <v-card-title>
          <v-col>
            <v-row justify="center">
              <h3>シンプル プラン</h3>
            </v-row>
            <v-row justify="center">月額料金：無料</v-row>
          </v-col>
        </v-card-title>
        <v-card-text class="grow">
          <ul>
            <li>面接設定1件につき1,000円</li>
            <li>ボットによる自動対応</li>
            <li>流入経路別QR/URL発行</li>
            <li>ビデオによる遠隔応募</li>
            <li>自動面接設定</li>
            <li>リファラル</li>
            <li>事前質問</li>
            <li>応募者情報管理</li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn :outlined="!selected(0)" color="primary" @click="onFreePlanClicked">{{label(0)}}</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12" sm="4" md="4" lg="4">
      <v-card class="flexcard" height="100%" outlined>
        <v-card-title>
          <v-col>
            <v-row justify="center">
              <h3>定額プラン</h3>
            </v-row>
            <v-row justify="center">月額料金：5,000円</v-row>
          </v-col>
        </v-card-title>
        <v-card-text class="grow">
          <ul>
            <li>面接設定5件/月 無料</li>
            <li>
              以降面接設定+5件ごとに+5,000円
              <table>
                <tr>
                  <td width="35%">6~10件</td>
                  <td wdith="65%">10,000円/月</td>
                </tr>
                <tr>
                  <td>11~15件</td>
                  <td>15,000円/月</td>
                </tr>
                <tr>
                  <td>16~20件</td>
                  <td>20,000円/月</td>
                </tr>
                <tr>
                  <td>21~25件</td>
                  <td>25,000円/月</td>
                </tr>
                <tr>
                  <td>26件~</td>
                  <td>30,000円/月（上限）</td>
                </tr>
              </table>
            </li>
            <li>下位プランの全機能</li>
            <li>人材/ファン プール</li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn :outlined="!selected(1)" color="primary" @click="on5KPlanClicked">{{label(1)}}</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12" sm="4" md="4" lg="4">
      <v-card class="flexcard" height="100%" outlined>
        <v-card-title>
          <v-col>
            <v-row justify="center">
              <h3>定額無制限プラン</h3>
            </v-row>
            <v-row justify="center">月額料金：30,000円</v-row>
          </v-col>
        </v-card-title>
        <v-card-text class="grow">
          <ul>
            <li>面接設定件数 無制限</li>
            <li>下位プランの全機能</li>
            <li>Googleしごと検索, Indeed, 求人ボックス 無料掲載</li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn :outlined="!selected(2)" color="primary" @click="on30KPlanClicked">{{label(2)}}</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'PlanPicker',
  data: () => ({
    plan: -1
  }),
  methods: {
    selected (plan) {
      return plan === this.plan
    },
    label (plan) {
      return this.selected(plan) ? '選択済み' : '選択する'
    },
    onFreePlanClicked () {
      this.plan = 0
      this.$emit('planPicked', 0)
    },
    on5KPlanClicked () {
      this.plan = 1
      this.$emit('planPicked', 1)
    },
    on30KPlanClicked () {
      this.plan = 2
      this.$emit('planPicked', 2)
    }
  }
}
</script>

<style scoped>
.flexcard {
  display: flex;
  flex-direction: column;
}
</style>
