<template>
  <v-container grid-list-md fill-height>
    <v-layout column justify-center>
      <v-flex shrink>
        <v-alert type="info" tile border="top">
          クーポンがご使用できない場合は
          <a class="white--text" href="mailto:hello@truffletechnologies.co.jp"
            >hello@truffletechnologies.co.jp</a
          >
          までご連絡ください。
        </v-alert>
      </v-flex>
      <v-flex shrink>
        <v-card>
          <v-card-subtitle class="pb-3">
            プランを選択してください。
            <br />※初期費用50,000円（キャンペーン中につき無料）
          </v-card-subtitle>
          <v-card-text>
            <plan-picker @planPicked="onPlanPicked" />
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex shrink>
        <v-card>
          <v-card-subtitle>カード情報を入力してください。</v-card-subtitle>
          <v-card-text>
            <v-sheet color="white">
              <div class ref="cardElement">
                <!-- A Stripe Element will be inserted here. -->
              </div>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex shrink v-if="coupon">
        <v-card>
          <v-list two-line dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="cyan--text text--darken-2"
                  >適用クーポン</v-list-item-title
                >
                <v-list-item-subtitle>{{
                  coupon.couponName
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-flex>
      <v-flex shrink>
        <v-card>
          <v-card-actions>
            <v-spacer />
            <CouponDialog @addedCoupon="addedCoupon" />
            <v-btn color="primary" @click="onCancel">戻る</v-btn>
            <v-btn
              :disabled="!planSelected"
              @click="createToken"
              :loading="generatingToken"
              color="primary"
              >サインアップ</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex shrink v-if="cardErrorMessage">
        <v-card>
          <v-sheet dark color="error" class="pa-3">
            <div id="card-errors" role="alert">{{ cardErrorMessage }}</div>
          </v-sheet>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import CouponDialog from './coupon-dialog'
import PlanPicker from './PlanPicker'
import { analyticsEvent, EVENT_NAMES } from '@/helpers/analytics'
var style = {
  base: {
    color: '#32325d',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
}

export default {
  components: {
    PlanPicker,
    CouponDialog
  },
  data () {
    return {
      customer: null,
      subscription: null,
      cardErrorMessage: null,
      card: null,
      stripe: null,
      generatingToken: false,
      coupon: null,
      plan: -1
    }
  },
  computed: {
    ...mapState('auth', ['userAuthEmail', 'userDisplayName']),
    planSelected () {
      return this.plan > -1
    }
  },
  mounted () {
    const stripe = this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_BROWSER_KEY)
    const elements = stripe.elements()
    const card = this.card = elements.create('card', { style })
    card.mount(this.$refs.cardElement)

    card.addEventListener('change', ({ error }) => {
      if (error) {
        this.cardErrorMessage = error.message
      } else {
        this.cardErrorMessage = null
      }
    })

    analyticsEvent(EVENT_NAMES.LOADED_CC_FORM)
  },
  methods: {
    priceIds (plan) {
      const PLAN_FREE = process.env.VUE_APP_STRIPE_PLAN_FREE
      const PLAN_5K = process.env.VUE_APP_STRIPE_PLAN_5K
      const PLAN_30K = process.env.VUE_APP_STRIPE_PLAN_30K

      let priceIds = []
      switch (plan) {
        case 0: // Free plan
          priceIds.push(PLAN_FREE)
          break
        case 1: // 5K plan
          priceIds.push(PLAN_5K)
          break
        case 2: // 30K plan
          priceIds.push(PLAN_30K)
          break
      }
      return priceIds
    },
    async createToken () {
      this.generatingToken = true

      const { token, error } = await this.stripe.createToken(this.card)
      if (error) {
        this.cardErrorMessage = error.message
      } else {
        this.cardErrorMessage = null
        const couponCode = this.coupon ? this.coupon.couponCode : null
        const priceIds = this.priceIds(this.plan)
        this.$emit('ccSubmit', { token, couponCode, priceIds })
      }

      this.generatingToken = false
    },
    onPlanPicked (plan) {
      this.plan = plan
    },
    addedCoupon (coupon) {
      this.coupon = coupon
    },
    onCancel () {
      this.$router.replace({ name: 'onboard-profile' })
    }
  }
}
</script>

<style>
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>
